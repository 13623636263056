<template>
    <div class="invitation-registration">

        <div class="container">
        <div class="pt-3 pb-3 pl-3 pr-3">
        
        <form @submit.prevent="onSubmitSignup" v-if="screen === 'signup'" autocomplete="off">
            <div class="form-group">
                <p class="h5 text-center">{{ $t('LoginModal.sign_up') }}</p>
            </div>
            <div class="form-group">
                <label for="inputEmail1">{{ $t('LoginModal.email') }}</label>
                <input type="email" class="form-control" name="email" id="inputEmail1" aria-describedby="emailHelp" :placeholder="$t('LoginModal.email')" v-model.trim="signup.email">
                <template v-if="$v.signup.email.$error">
                <small class="form-text text-warning" v-if="!$v.signup.email.required">{{ $t('LoginModal.field_is_required') }}</small>
                <small class="form-text text-warning" v-if="!$v.signup.email.email">{{ $t('LoginModal.enter_your_email') }}</small>
                </template>
            </div>
            <div class="form-group">
                <label for="inputPassword1">{{ $t('LoginModal.password') }}</label>
                <input type="password" class="form-control" name="password" id="inputPassword1" :placeholder="$t('LoginModal.password')" v-model.trim="signup.password">
                <template v-if="$v.signup.password.$error">
                <small class="form-text text-warning" v-if="!$v.signup.password.required">{{ $t('LoginModal.enter_your_password') }}</small>
                <small class="form-text text-warning" v-if="!$v.signup.password.min">{{ $t('LoginModal.at_least_8_characters') }}</small>
                </template>
            </div>
            <div class="form-group">
                <label for="inputConfirmPassword1">{{ $t('LoginModal.confirm_password') }}</label>
                <input type="password" class="form-control" name="confirmPassword" id="inputConfirmPassword1" :placeholder="$t('LoginModal.confirm_password')" v-model.trim="signup.confirmPassword">
                <template v-if="$v.signup.confirmPassword.$error">
                <small class="form-text text-warning" v-if="!$v.signup.confirmPassword.required">{{ $t('LoginModal.type_your_password_again') }}</small>
                <small class="form-text text-warning" v-if="!$v.signup.confirmPassword.sameAsPassword">{{ $t('LoginModal.passwords_must_match') }}</small>
                </template>
            </div>
            <div class="form-group">
                <label for="selectOwnership">{{ $t('LoginModal.type_of_ownership') }}</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <label class="input-group-text" for="selectOwnership"><i class="fas" :class="ownershipIcon"></i></label>
                    </div>
                    <select class="custom-select" name="ownership" id="selectOwnership" v-model="signup.ownership">
                        <option v-for="o in ownerships" :value="o.id" :key="o.id">{{ $t('LoginModal.' + o.name) }}</option>
                    </select>
                </div>
            </div>
            <div class="form-group">
                <button type="submit" class="btn btn-zhako w-100">
                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" v-show="spinnerVisible"></span>
                    {{ $t('LoginModal.sign_up') }}
                </button>
                <small class="form-text"><router-link :to="{name: 'user-agreement'}" target="_blank">{{ $t('LoginModal.by_creating') }}</router-link></small>
            </div>
        </form>

        <div v-if="screen === 'activate'">
            <p class="h5 text-center">{{ $t('LoginModal.sign_up') }}</p>
            <p>{{ $t('LoginModal.activate_message') }}</p>
        </div>

        </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

.invitation-registration {
    width:500px;
    margin: 0 auto;
    background-color: #553DB4;
    color: white;

    .container {
        background-color: #553DB4;
        color: white;

        a {
            color: white;
            text-decoration: underline !important;
        }
    }
}
</style>

<script>

import { required, minLength, email, numeric, sameAs } from "vuelidate/lib/validators";

export default {
    name: "InvitationRegistration",
    data: () => ({
        ownerships: [
            { id: 3, name: "individual",   icon: 'fa-user' },
            { id: 2, name: "entrepreneur", icon: 'fa-user-tie' },
            { id: 1, name: "company",      icon: 'fa-building' }
        ]
        ,screen: 'signup'
        ,spinnerVisible: false
        ,signup: {
            invitation: null,
            email: null,
            password: null,
            confirmPassword: null,
            ownership: 1
        }
    }),
    validations: {
        signup: {
            email: { required, email },
            password: { required, min: minLength(8) },
            confirmPassword: { required, sameAsPassword: sameAs('password') },
            ownership: { required, numeric }
        }
    },
    mounted() {
        this.onCreate();
    },
    methods: {
        onCreate() {
            if (this.$route.params.email) {
                this.signup.email = this.$route.params.email;
            }
            if (this.$route.params.id) {
                this.signup.invitation = this.$route.params.id;
            }
        },
        onSubmitSignup() {
            this.$v.signup.$touch();
            if (! this.$v.signup.$invalid) {
                this.showSpinner();
                this.$store.dispatch("REGISTRATION", this.signup)
                    .then(() => {
                        this.hideSpinner();
                        this.changeScreen('activate');
                    })
                    .catch(e => e.message && this.$toast.error(e.message) && this.hideSpinner())
                    ;
            }
        },
        changeScreen(scr) {
            this.screen = scr;
        },
        showSpinner() {
            this.spinnerVisible = true;
        },
        hideSpinner() {
            this.spinnerVisible = false;
        },
    },
    computed: {
        ownershipIcon() {
            return this.ownerships.reduce((a,c) => c.id == this.signup.ownership ? c : a).icon;
        }
    }
}
</script>

